import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import "../dev-styles.scss";
import { ButtonContainer } from "../../../components/mdx/mdx-button-container/index.tsx";
import { SkillStack, addColorToSkills } from "../../../components/skillStack/index.tsx";
export const buttons = [{
  text: "git repo",
  url: "https://github.com/ArohanD/face-browser-extension"
}];
export const tags = ["Chrome Extension", "TensorFlow", "Augmented Reality", "Accessibility", "WebGL", "WASM"];
export const _frontmatter = {};
const layoutProps = {
  buttons,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className='mdx-default-wrapper'>
      <h1>{`Face-Browser Chrome Extension`}</h1>
      <p>{`A chrome extension that allows users to navigate the web using only face movements as an accessibility feature. The app uses a face-landmark ai model in a TensorFlow backend to achieve the pointer movement.`}</p>
      <ButtonContainer buttons={buttons} mdxType="ButtonContainer" />
      <SkillStack skills={addColorToSkills(tags)} mdxType="SkillStack" />
      <iframe className="mdx-video mdx-iframe" src="https://www.youtube.com/embed/Hvl9UqmbOEM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      